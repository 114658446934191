import React from 'react';
import { navigate } from 'gatsby';

import { Box, Typography, Container } from '@mui/material';
import { pink } from '@mui/material/colors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HomeIcon from '@mui/icons-material/Home';

import { FloatLogoDarkLayout } from '@components/layout';
import { SimpleButton } from '@components/form';

const NeedVerifyPassError = ({ text }) => {
  return (
    <FloatLogoDarkLayout>
      <Box
        sx={{
          width: '6rem',
          height: '6rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: 'linear-gradient(to right bottom, #828b9b, #535b6e, #3d4458)',
          borderRadius: '50%',
          color: pink[500],
          boxShadow: (theme) => theme.shadows[4],
          '& svg': {
            width: '5rem',
            height: '5rem',
          },
        }}
      >
        <HighlightOffIcon />
      </Box>
      <Container maxWidth="sm">
        <Typography variant="h4" align="center" sx={{ mt: '1rem' }}>
          Não encontramos nenhum usuário com esse e-mail.
        </Typography>
        <Typography variant="h5" align="center" sx={{ mt: '1.5rem' }}>
          {text ?? 'Verifique se o e-mail digitado está correto.'}
        </Typography>
        <SimpleButton
          sx={{ mt: '1.5rem', bgcolor: pink[500], '&:hover': { bgcolor: pink[600] } }}
          onClick={() => navigate('/')}
        >
          <HomeIcon sx={{ mr: '1rem' }} /> Voltar ao início
        </SimpleButton>
      </Container>
    </FloatLogoDarkLayout>
  );
};

export default NeedVerifyPassError;
